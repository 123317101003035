import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Container } from 'postcss'
import {
    ChartBarIcon,
    BookmarkAltIcon,
    CalendarIcon,
    PhoneOutgoingIcon,
    KeyIcon,
    UserGroupIcon,
    MapIcon,
    HomeIcon,
    PresentationChartLineIcon
  } from '@heroicons/react/outline'

const StoryElements = [
    { date: '2003 Progetto LUME - RJ' ,description: 'Giovanni Rossetti e Roberto Jaquinta uniscono le proprie competenze ed esperienze e fondano a Lumino la Carrozzeria Lume il 4 Aprile 2003. ',  icon: KeyIcon},
    { date: '2013 Consolidamento ' ,description: 'Dopo 10 anni di intensa attività a 2 ed un portfolio di oltre 1000 clienti vengono assunti 2 collaboratori. L’attività si espande anche grazie a diversi investimenti.',  icon: UserGroupIcon},
    { date: '2018 Revisione strategia' ,description: 'Con l’acquisizione di ordini sempre più importanti e la sottoscrizione di un partenariato vengono perfezionate 2 ulteriori assunzioni. RJ decidono di investire per continuare ad assicurare il massimo livello di qualità per i propri clienti.',  icon: PresentationChartLineIcon},
    { date: '2020 Progetto nuova Lume ' ,description: 'Viene deciso l’investimento su terreno vergine per una nuova Carrozzeria a Castione. Il mandato di ingegneria ed architettura viene assegnato. Le specifiche del processo vengono perfezionate ed i fornitori selezionati. Inizia la costruzione dello stabile. ',  icon: MapIcon},
    { date: '2022 Trasloco in nuova sede ' ,description: 'Con la fine dell’anno 2021 viene raggiunto il traguardo dei 4000 clienti. Il nuovo stabile è pronto, i nuovi impianti vengono consegnati ed il 16 Maggio avviene il trasloco presso la nuova sede di Castione.',      icon: HomeIcon}
    
  ]
  
  export default function Story() {
     return (

<section className="text-gray-600 body-font max-w-7xl mx-auto px-4 sm:px-6">
  <div className="container px-5 py-24 mx-auto flex flex-wrap">
  <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
       <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-black" >Storia</h1>
       <div class="h-1 w-20 bg-yellow-300 rounded"></div>
     </div>
  

  {StoryElements.map((item) => (
    // sm:items-center
  <div className="flex relative pt-10 pb-20 sm:items-center w-2/3 mx-auto">
  <div className="h-full w-6 absolute inset-0 flex items-center justify-center">
    <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
  </div>
  <div className="flex-shrink-0 w-6 h-6 rounded-full mt-10 sm:mt-0 inline-flex items-center justify-center bg-yellow-300 text-white relative z-10 title-font font-medium text-sm"></div>
  <div className="flex-grow md:pl-8 pl-6 flex sm:items-center items-start flex-col sm:flex-row">
    <div className="flex-shrink-0 w-24 h-24 bg-gray-100 text-yellow-300 rounded-full inline-flex items-center justify-center">
      <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-12 h-12" viewBox="0 0 24 24">
        {/* <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path> */}
        <item.icon className="flex-shrink-0 h-6 w-6 " /*text-indigo-600*/  aria-hidden="true" />
      </svg>
    </div>
    <div className="flex-grow sm:pl-6 mt-6 sm:mt-0">
      <h2 className="font-medium title-font text-gray-900 mb-1 text-xl">{item.date}</h2>
      <p className="leading-relaxed">{item.description}</p>
    </div>
  </div>
</div>
  ))}

   


  </div>
</section>

    )
}