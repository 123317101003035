/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  BookmarkAltIcon,
  CalendarIcon,
  PhoneOutgoingIcon,
  MailIcon,
  LocationMarkerIcon,
  ChartBarIcon,
  CursorClickIcon,
  LogoutIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'


const solutions = [
  {
    name: 'Carrozzeria',
    description: 'text text tex .',
    href: '#',
    icon: ChartBarIcon,
  },
  {
    name: 'Title',
    description: 'text text tex .',
    href: '#',
    icon: CursorClickIcon,
  },
  { name: 'Title', description: "text text tex .", href: '#', icon: ShieldCheckIcon },
  {
    name: 'Title',
    description: "text text .",
    href: '#',
    icon: ViewGridIcon,
  },
  {
    name: 'Title',
    description: 'text.',
    href: '#',
    icon: RefreshIcon,
  },
]
const callsToAction = [
  { name: 'Watch Demo', href: '#', icon: PlayIcon },
  { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]
const resources = [
  // {
  //   name: 'Prenota appuntamento',
  //   description: 'Prenota il tuo appuntamento online',
  //   href: '#',
  //   icon: CalendarIcon,
  // },
  {
    name: 'Chiamaci',
    description: '+41 (0)91 835 81 21',
    href: 'tel: +41918358121',
    icon: PhoneOutgoingIcon,
  },
  {
    name: 'Mail',
    description: 'info@carrozzerialume.ch',
    href: 'mailto:info@carrozzerialume.ch',
    icon: MailIcon,
  },
  { name: 'Mappe', description: 'Via S. Bernardino 6, 6532 Arbedo-Castione', href: 'https://goo.gl/maps/qArpc9EaC4GCxi4T7', icon: LocationMarkerIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {
  return (
    <Popover className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="./">
              <span className="sr-only">Lume</span>
              <img
                className="h-8 w-auto sm:h-10"
                src="img/lume-logo.svg"
                alt=""
              />
            </a>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 ">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>
          <Popover.Group as="nav" className="hidden md:flex space-x-10">

            {/* <a href="#" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Menu1
            </a> */}
            {/* <a href="./tesla" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Tesla
            </a> */}
            

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 ' /*focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 */
                    )}
                  >
                    <span>Contatto</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-40 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md sm:px-0">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          
                          {resources.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              target="_blank"
                              className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                            >
                              <item.icon className="flex-shrink-0 h-6 w-6 " aria-hidden="true" /> 
                              <div className="ml-4">
                                <p className="text-base font-medium text-gray-900">{item.name}</p>
                                <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                              </div>
                            </a>
                          ))}
                          
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>

          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            

            {/* Prenota Appuntamento bottone */}

            {/* <button class="bg-transparent hover:bg-black text-gray-500 inline-flex font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
</svg>
              <span>Prenota appuntamento</span>
            </button>
          */}


          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 z-40 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <img
                    className="h-8 w-auto"
                    src="img/lume-logo.svg"
                    alt="Workflow"
                  />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 ">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              {/* <div className="mt-6">
                <nav className="grid gap-y-8">
                  <a className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50" href="#">
                  <LogoutIcon className="flex-shrink-0 h-6 w-6 text-gray-900" aria-hidden="true" />
                  <span className="ml-3 text-base font-medium text-gray-900">Menu 0</span> 
                    </a>
                    <a className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50" href="#">
                  <LogoutIcon className="flex-shrink-0 h-6 w-6 text-gray-900" aria-hidden="true" />
                  <span className="ml-3 text-base font-medium text-gray-900">Menu 1</span> 
                    </a>
                    <a className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50" href="#">
                  <LogoutIcon className="flex-shrink-0 h-6 w-6 text-gray-900" aria-hidden="true" />
                  <span className="ml-3 text-base font-medium text-gray-900">Menu 2</span> 
                    </a>
                  
                </nav>
                
              </div> */}
              
            </div>
            
            <div className="grid py-6 px-5 space-y-6 grid-cols-1">
            {resources.map((item) => (
                            <a
                              key={item.name}
                              href={item.href}
                              target="_blank"
                              class="w-full "
                              >
                              <button class="w-full justify-center bg-transparent hover:bg-gray-900 text-gray-500 inline-flex font-semibold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded">
                              <item.icon className="flex-shrink-0 h-6 w-6 " aria-hidden="true" />
                                <span>{item.name}</span>
                              </button>
                              </a>

            ))}


            
              {/* <div className="grid gap-y-4 gap-x-8">
                <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Pricing
                </a>

                <a href="#" className="text-base font-medium text-gray-900 hover:text-gray-700">
                  Docs
                </a>
                 
         
              </div> */}
            </div>
          </div>
        </Popover.Panel>
      </Transition>





    </Popover>
  )
}
