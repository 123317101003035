import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import NavBar from './NavBar';
import reportWebVitals from './reportWebVitals';
import Home from './Home'
import NewSiteSection from './NewSiteSection'
import FooterSection from './Footer';
import InfoSection from './InfoSection';
import ServiceSection from './ServiceSection';
import Story from './story';
import Partner from './PartnersSection';

import { BrowserRouter as Router, Routes, Route }
    from 'react-router-dom';
    
    import Tesla from "./tesla"

// ReactDOM.render(
//   <React.StrictMode>
//     <NavBar />

//     <NewSiteSection />

//     <ServiceSection/>
//     <Story/>

//     <FooterSection />
    
//   </React.StrictMode>,
//   document.getElementById('root')
// );
ReactDOM.render(
  <React.StrictMode>
        <Router>
    <NavBar />
    <Routes>
        <Route exact path='/' element={<Home />} />
        <Route path='/tesla' element={<Tesla />} />
    </Routes>
    <FooterSection />
</Router>
    
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
