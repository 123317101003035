/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Container } from 'postcss'

const Services = [
  { title: 'Eccellenza professionale', subtitle: '', img: './img/IMG_5565.png' , text: 'Un team competente e formato continuamente per soddisfare i nostri clienti.'},
  { title: 'Spazi, attrezzature e servizi', subtitle: '', img: './img/IMG_5575.png' , text: 'Una moderna infrastruttura al più recente stato dell`arte per eseguire qualsiasi intervento ed offrire un servizio completo.'},
  { title: 'Esperienza', subtitle: '', img: './img/IMG_5578.png' , text: 'Soluzioni innovative grazie ad una solida esperienza accumulata e profonda conoscenza della supply chain automotive.'},
  { title: 'Ambiente ed etica', subtitle: '', img: './img/DJI_0551.JPG' , text: 'Attenzione alla sostenibilità nelle nostre scelte dei materiali, delle fonti e dei nostri partner.'},
 
]

export default function ServiceSection() {
  return ( 


 <section class="text-gray-600 body-font pt-6 max-w-7xl mx-auto px-4 sm:px-6">
 <div class="container px-5 py-24 mx-auto">
   <div class="flex flex-wrap w-full mb-20">
     {/* <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
       <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-black" >Nuova sede</h1>
       <div class="h-1 w-20 bg-yellow-300 rounded"></div>
     </div> */}
     
   </div>
   <div class="flex flex-wrap -m-4">
   {Services.map((item) => (

      <div class="xl:w-1/4 md:w-1/2 p-4">
       <div class="bg-gray-100 p-6 rounded-lg">
         <img class="h-40 rounded w-full object-cover object-center mb-6" src={item.img} alt="content" />
         <h3 class="tracking-widest text-yellow-300 text-xs font-medium title-font">{item.subtitle}</h3>
         <h2 class="text-lg text-gray-900 font-medium title-font mb-4">{item.title}</h2>
         <p class="leading-relaxed text-base">{item.text}</p>
       </div>
      </div>

    ))}  



   </div>
 </div>
</section>

)
}