import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import NavBar from './NavBar';
import reportWebVitals from './reportWebVitals';
import Home from './Home'
import NewSiteSection from './NewSiteSection'
import FooterSection from './Footer';
import InfoSection from './InfoSection';
import ServiceSection from './ServiceSection';
import Story from './story';
import Partner from './PartnersSection';
import img1 from './img/tesla_rapporto.png'
import img2 from './img/tesla_rapporto_lume.png'
import img3 from './img/IMG_1974.png'

const Services = [
  { title: 'Diagnostica completa', subtitle: '', img: img3 , text: 'Ci annoiavamo cosi ora siamo partner tesla'},
  { title: 'Ricambi originali', subtitle: '', img: './img/tesla_rapporto.png' , text: 'Ci annoiavamo cosi ora siamo partner tesla'},
  { title: 'Garanzia', subtitle: '', img: './img/tesla_rapporto.png' , text: 'Le riparazioni della carrozzeria non riguardano solo problemi estetici — ma sono importanti anche per garantire la funzionalità, la sicurezza e la longevità della tua Tesla.'},
  { title: 'Personale formato', subtitle: '', img: './img/tesla_rapporto.png' , text: ''}
 
]



export default function Tesla() {
  return (


    <section class="text-black body-font pt-6 max-w-7xl mx-auto px-4 sm:px-6">
        <div class="lg:w-1/2 w-full mb-6 lg:mb-0">
          {/* <h1 class="sm:text-3xl text-2xl font-medium title-font mb-2 text-black" >Tesla</h1> */}
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">La vostra Tesla </span>{' '}
                <span className="block text-yellow-300 xl:inline">in ottime mani</span>
              </h1>
          <div class="h-1 w-20 bg-yellow-300 rounded"></div>
        </div>
    <div class="container px-5 py-24 mx-auto ">

      <div class="w-full  ">
     
   
         <div class="xl:w-3/4 md:w-3/4 p-4 mx-auto">
          {/* <div class="bg-black p-6 rounded-lg"> */}
            <img class=" bg-black rounded-lg w-full object-contain object-center mb-6" src={img2} alt="content" />
            
          {/* </div> */}
         </div>
   
  
   
   
   
      {/* <button class="object-center mx-auto bg-transparent hover:bg-black text-gray-500 inline-flex font-bold hover:text-white py-2 px-4 border border-gray-500 hover:border-transparent rounded"> */}
            {/* <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"> */}
  {/* <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" /> */}
{/* </svg> */}
              {/* <span>Guarda il trailer</span> */}
            {/* </button> */}
      </div>
    <div class="flex flex-wrap -m-4">
{/*       
   {Services.map((item) => (

      <div class="xl:w-1/4 md:w-1/2 p-4">
       <div class="bg-gray-100 p-6 rounded-lg">
         <img class="h-40 rounded w-full object-cover object-center mb-6" src={item.img} alt="content" />
         <h3 class="tracking-widest text-yellow-300 text-xs font-medium title-font">{item.subtitle}</h3>
         <h2 class="text-lg text-black font-medium title-font mb-4">{item.title}</h2>
         <p class="leading-relaxed text-black text-base">{item.text}</p>
       </div>
      </div>

    ))}   */}



   </div>
    </div>

   </section>


)}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
