/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import { MenuIcon, XIcon } from '@heroicons/react/outline'
import { Container } from 'postcss'

const navigation = [
  { name: 'Product', href: '#' },
  { name: 'Features', href: '#' },
  { name: 'Marketplace', href: '#' },
  { name: 'Company', href: '#' },
]

export default function NewSiteSection() {
  return (
      <section className="pt-6 max-w-7xl mx-auto px-4 sm:px-6">
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto ">
        <div className="relative z-10 pb-8  sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32 rounded-lg">
          <svg
            className="hidden lg:block   absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2 "
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true" 
          >
          </svg>

          <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-10 bg-white rounded-lg">
              
            <div className="sm:text-center lg:text-left">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block xl:inline">La nostra </span>{' '}
                <span className="block text-yellow-300 xl:inline">Nuova sede</span>
              </h1>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                Una soluzione che raccoglie la nostra esperienza maturata in 20 anni ascoltando la voce dei nostri clienti. Da Lumino a Castione pochi metri ma un grande salto tecnologico.
              </p>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0  lg:w-1/2 ">
        <img
          className="h-56 w-full object-cover sm:h-60 md:h-96 lg:w-full lg:h-full rounded-lg"
          src='./img/DJI_0563.JPG'
          alt=""
        />
      </div>
    </div>
    </section>
  )
}
