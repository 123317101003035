/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline'
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import NavBar from './NavBar';
import reportWebVitals from './reportWebVitals';
import NewSiteSection from './NewSiteSection'
import FooterSection from './Footer';
import InfoSection from './InfoSection';
import ServiceSection from './ServiceSection';
import Story from './story';
import Partner from './PartnersSection';
import Tesla from './tesla'


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Home() {
  return (
    <React.StrictMode>
    {/* <NavBar /> */}
    {/* <div className=" max-w-7xl mx-auto px-4 sm:px-6"> */}
    <NewSiteSection />
    {/* </div> */}
    {/* <InfoSection /> */}
    {/* <div className=" max-w-7xl mx-auto px-4 sm:px-6"> */}
    <ServiceSection/>
<Tesla />
    <Story/>
    {/* <Partner/> */}
    {/* </div> */}
    {/* <FooterSection /> */}
  </React.StrictMode>
  )
}
